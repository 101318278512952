import React, { useState } from "react";
import Select from "react-select";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import classnames from "classnames";
import axios from 'axios';

import { COUNTRIES, TITLES_LIST } from "../../global/js/helpers";

import "./apply-block.scss";
import { TrackedLink } from "..";

const API_ENDPOINT_LEADER = process.env.API_ENDPOINT_LEADER;

const validationSchema = Yup.object().shape({
    custom_fields: Yup.object().shape({
        w3_T: Yup.string().required('Required'),
        w9_T: Yup.string().required('Required'),
        w10_T: Yup.string().min(1, 'Too Short!').required('Required')
    }),
    email: Yup.string().required('Required').email('Please enter a valid email address'),
    country: Yup.string().required('Required'),
});

const ApplyBlock = () => {
    const [formError, setFormError] = useState(undefined);
    const [successData, setSuccessData] = useState(undefined);

    /**
    * Store initial form state.
    * w3_T = username
    * w9_T = game_title
    * w10_T = description
    */
    const initialValues = {
        country: '',
        email: '',
        custom_fields: {
            w10_T: '',
            w9_T: '',
            w3_T: '',
        },
    }

    const handleSubmit = (values) => {
        axios.post(API_ENDPOINT_LEADER, values)
            .then((res) => {
                if (res.data === 'Accepted') {
                    // Update UI to show Success MSG.
                    setSuccessData(values);
                }
                else {
                    setFormError(`There was an error. Please try again later.`)
                }
            })
            .catch(error => {
                // Update UI to show Error MSG.
                console.log('submit error: ', error);
                setFormError(`${error}. Please try again later.`)
            });
    };

    return (
        <>
            <div className="form-page apply-form">
                <div className="form-page-header">
                    <h2 className="partie-block__title">Today a reader, tomorrow a Leader.</h2>
                    <h2 className="secondary-header">Get started below</h2>
                </div>
                {successData ? (
                    <div className="success-message">
                        <p>Thank you,
                            your application has been
                            submitted for review.</p>
                        <p>
                            We will contact you
                            for additional information
                            based on your eligibility.</p>
                        <TrackedLink type="btn btn--accent" to="https://app.partie.com" children="To Partie" />
                    </div>
                ) : (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form className="partie-form" id="partie-form">
                            <Field name="custom_fields.w9_T">
                                {({ form, meta }) => (
                                    <div className={classnames("partie-form__field-container", {
                                        'partie-form__field-container--error': meta.error && meta.touched
                                    })}>
                                        <label
                                            className="partie-form__label"
                                            id="game_title-label"
                                            htmlFor="custom_fields.w9_T"
                                        >
                                            Game Title *
                                        </label>
                                        <Select
                                            aria-labelledby="platform-label"
                                            classNamePrefix="partie-form__select"
                                            inputId="custom_fields.w9_T"
                                            options={TITLES_LIST}
                                            onChange={(e) => {
                                                form.setFieldValue("custom_fields.w9_T", e.value);
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 10,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: "#6e4aff",
                                                    primary25: "#6e4aff40",
                                                },
                                            })}
                                        />
                                        {(meta.error && meta.touched) && (
                                            <span className="partie-form__field-caption">{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                            <Field name="country">
                                {({ form, meta }) => (
                                    <div className={classnames("partie-form__field-container", {
                                        'partie-form__field-container--error': meta.error && meta.touched
                                    })}>
                                        <label
                                            className="partie-form__label"
                                            id="country-label"
                                            htmlFor="country"
                                        >
                                            Region *
                                        </label>
                                        <Select
                                            aria-labelledby="country-label"
                                            classNamePrefix="partie-form__select"
                                            inputId="country"
                                            options={COUNTRIES}
                                            onChange={(e) => {
                                                form.setFieldValue("country", e.value);
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 10,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: "#6e4aff",
                                                    primary25: "#6e4aff40",
                                                },
                                            })}
                                        />
                                        {(meta.error && meta.touched) && (
                                            <span className="partie-form__field-caption">{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                            <Field name="email">
                                {({ field, meta }) => (
                                    <div className={classnames("partie-form__field-container", {
                                        'partie-form__field-container--error': meta.error && meta.touched
                                    })}>
                                        <label className="partie-form__label" htmlFor="email">
                                            Email *
                                        </label>
                                        <input
                                            className="partie-form__field"
                                            id="email"
                                            type="email"
                                            onChange={field.onChange}
                                        />
                                        {(meta.error && meta.touched) && (
                                            <span className="partie-form__field-caption">{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                            <Field name="custom_fields.w3_T">
                                {({ field, meta }) => (
                                    <div className={classnames("partie-form__field-container", {
                                        'partie-form__field-container--error': meta.error && meta.touched
                                    })}>
                                        <label className="partie-form__label" htmlFor="custom_fields.w3_T">
                                            Partie Username *
                                        </label>
                                        <input
                                            className="partie-form__field"
                                            id="custom_fields.w3_T"
                                            type="text"
                                            onChange={field.onChange}
                                        />
                                        {(meta.error && meta.touched) && (
                                            <span className="partie-form__field-caption">{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                            <Field name="custom_fields.w10_T">
                                {({ field, meta }) => (
                                    <div className={classnames("partie-form__field-container", {
                                        'partie-form__field-container--error': meta.error && meta.touched
                                    })}>
                                        <label className="partie-form__label" htmlFor="custom_fields.w10_T">
                                            Description *
                                        </label>
                                        <textarea
                                            className="partie-form__field"
                                            id="custom_fields.w10_T"
                                            type="text"
                                            maxLength="1000"
                                            rows="12"
                                            onChange={field.onChange}
                                        />
                                        {(meta.error && meta.touched) && (
                                            <span className="partie-form__field-caption">{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                            <div className="partie-form__field-container">
                                <button className="btn btn--accent" type="submit">
                                    Submit
                                </button>
                                {formError && (
                                    <span className="partie-form__field-caption">{formError}</span>
                                )}
                            </div>
                        </Form>
                    </Formik>
                )}
            </div>
        </>
    );
};

export default ApplyBlock;